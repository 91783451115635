import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as CarouselWrapper } from 'react-responsive-carousel';

const Carousel = ({ items }) => {
  return (
    <CarouselWrapper
      stopOnHover
      showIndicators={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      showThumbs={false}
    >
      {items?.map((item, idx) => {
        return (
          <div className='w-100 flex justify-center items-center'>
            <div className='img-container'>
              <img src={item.image[0].url} alt='' />
            </div>
          </div>
        );
      })}
    </CarouselWrapper>
  );
};

export default Carousel;
