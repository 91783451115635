import React from 'react';

const ClientList = ({ clients }) => {
  console.log(clients);
  return (
    <div className='flex flex-wrap justify-center items-center'>
      {clients?.map((client) => (
        <div className='w-20-l w-50-m w-100 ph3-l mv4' key={client?.url}>
          <a
            className='link dim flex justify-center items-center'
            href={client?.url}
            target='_blank'
            rel='noreferrer noopener'
          >
            <img className='w-50' src={client?.image[0]?.url} alt='' />
          </a>
        </div>
      ))}
    </div>
  );
};

export default ClientList;
