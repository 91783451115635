import React, { useContext, useState } from 'react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import Selection from '../Selection';

import 'react-day-picker/lib/style.css';
import { store } from '../../../context/store';
import { nextStep, setBookingData } from '../../../context/actions';

const SelectionContainer = ({
  text,
  apptName,
  appt,
  items,
  bookingType,
  addons,
  dates,

  handleClick,
  loadTimes,
  times,
}) => {
  const today = new Date();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [selectedAddons, setAddons] = useState([]);
  const { dispatch } = useContext(store);
  const selectDate = (day) => {
    setDate(day);
    loadTimes(appt.id, appt.addonIDs, moment(day).format('YYYY-MM-DD'));
  };

  const handleAddonClick = (addon) => {
    setAddons((prevState) => [...prevState, addon]);
  };

  const removeAddon = (id) => {
    setAddons((prevState) => prevState.filter((addon) => addon.id !== id));
  };

  const getAddonIds = () => {
    return selectedAddons.map((addon) => addon.id);
  };

  const createBookingData = () => {
    const appointment = {
      datetime: time,
      appointmentTypeID: appt.id,
      addonIDs: getAddonIds(),
    };
    const lineItems = {
      appointment: appt,
      addons: selectedAddons,
    };

    dispatch(setBookingData({ appointment, lineItems }));
    dispatch(nextStep());
  };

  return (
    <section className='pb5'>
      {appt && date && time && (
        <div
          className='dt w-100 bb b--black-05 pa3 hover-bg-near-white pointer bg-lavender'
          onClick={createBookingData}
        >
          <div className='dtc v-mid'>
            <h2
              className={`f6 f5-ns fw6 lh-title mv0 flex 
              } justify-between items-baseline tracked `}
            >
              Select {moment(time).format('LLL')}
            </h2>
          </div>
          <div className='dtc v-mid'>
            <div className='w-100 tr'>
              <button className='f6 button-reset bg-transparent bn b--black-10 dim pointer pv1 black-60'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  class='feather feather-chevron-right'
                >
                  <polyline points='9 18 15 12 9 6' />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      <header className='w-100 bb b--black-10 pa3 ttu tracked'>
        {text(bookingType || apptName)}
      </header>
      {items &&
        items?.map((item, idx) => {
          return (
            <Selection item={item} key={item.id} handleClick={handleClick} />
          );
        })}
      {addons &&
        addons?.map((item, idx) => {
          return appt.addonIDs.includes(item.id) ? (
            <Selection
              item={item}
              key={item.id}
              handleClick={handleAddonClick}
              isAddon={addons && addons?.length > 0}
              isSelected={selectedAddons.includes(item)}
              removeAddon={removeAddon}
            />
          ) : null;
        })}
      {dates && (
        <>
          <header className='w-100 bb b--black-10 pa3 ttu tracked'>
            Select a Date
          </header>
          <div className='flex flex-column items-center mv2'>
            <DayPicker
              disabledDays={{ before: today }}
              selectedDays={date}
              onDayClick={(day) => selectDate(day)}
            />
            {date && (
              <p className='f6 tracked'>
                You've selected {date.toLocaleDateString()}
              </p>
            )}
          </div>
        </>
      )}
      {date && times && (
        <div className='mt3'>
          <header
            className={` ${
              times.length === 0 && 'red'
            } w-100 bb bt b--black-10 pa3 ttu tracked`}
          >
            {times.length > 0
              ? 'Select a Time'
              : 'No Times Available for this Date'}
          </header>
          {times.length > 0 && (
            <div className='mt3 flex justify-center'>
              <select
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className='w-50'
              >
                {times?.map((time) => (
                  <option value={time.time} key={time.time}>
                    {moment(time.time).format('h:mm A')}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default SelectionContainer;
