import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const Banner = ({ message }) => {
  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlayMarquee(true);
    }, 2000);
  }, []);
  return (
    <motion.div className='banner' variants={banner}>
      <BannerRowTop title={'photo'} message={message} />
      <BannerRowCenter
        title={[{ 1: 'Forever' }, { 2: 'Eternal' }, { 3: 'Moments' }]}
        playMarquee={playMarquee}
      />
      <BannerRowBottom title={'studio'} />
    </motion.div>
  );
};

const AnimatedLetters = ({ title, disabled, withSpaces }) => (
  <motion.span
    className='row-title'
    variants={disabled ? null : banner}
    initial='initial'
    animate='animate'
  >
    {withSpaces
      ? title.map((words) =>
          Object.values(words).map((word) => (
            <motion.span
              className={`${word === 'Eternal' ? 'saol' : ''} row-letter`}
              variants={disabled ? null : letterAni}
            >
              {word}&nbsp;&nbsp;
            </motion.span>
          ))
        )
      : [...title].map((letter) => (
          <motion.span
            className='row-letter'
            variants={disabled ? null : letterAni}
          >
            {letter}
          </motion.span>
        ))}
  </motion.span>
);

const BannerRowTop = ({ title, message }) => {
  return (
    <div className={'banner-row'}>
      <div className='row-col'>
        <AnimatedLetters title={title} />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: 'easeInOut',
          duration: 1,
          delay: 0.4,
        }}
        className='row-col'
      >
        <span className='row-message'>{message}</span>
      </motion.div>
    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={'banner-row center'}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }}
        className='scroll'
      >
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: 'easeInOut',
            duration: 1,
            delay: 1.8,
          }}
        >
          scroll
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: 'easeInOut',
            duration: 1,
            delay: 1.8,
          }}
        >
          down
        </motion.span>
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && 'animate'}`}>
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}
        className='marquee__inner'
      >
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
      </motion.div>
    </div>
  );
};

export default Banner;
