import * as TYPES from './types';

export function setAppointmentTypes(data = {}) {
  let portraits = [];
  let rentals = [];
  data.filter((obj) => {
    if (obj.category === 'Rentout' && obj.active) {
      rentals.push(obj);
    } else if (obj.category === 'Portrait' && obj.active) {
      portraits.push(obj);
    }
    return obj;
  });
  return {
    type: TYPES.FETCH_APPOINTMENT_TYPES,
    payload: {
      portraits,
      rentals,
    },
  };
}
export function setAppointmentAddons(data = []) {
  return {
    type: TYPES.FETCH_APPOINTMENT_ADDONS,
    payload: data,
  };
}

export function setAppointmentDates(data = []) {
  return {
    type: TYPES.FETCH_APPOINTMENT_DATES,
    payload: data,
  };
}

export function setAppointmentTypeId(id, appointment = {}) {
  return {
    type: TYPES.SET_APPOINTMENT_TYPE_ID,
    payload: { id, selectedAppointment: appointment },
  };
}

export function setHomePage(homePage = {}) {
  return {
    type: TYPES.SET_HOMEPAGE,
    payload: homePage,
  };
}

export function setPortraitsPage(portraitsPage = {}) {
  return {
    type: TYPES.SET_PORTRAITS_PAGE,
    payload: portraitsPage,
  };
}
export function setRentalsPage(rentalsPage = {}) {
  return {
    type: TYPES.SET_RENTALS_PAGE,
    payload: rentalsPage,
  };
}

export function toggleBookingOpen(isOpen) {
  return {
    type: TYPES.TOGGLE_BOOKING_OPEN,
    payload: isOpen,
  };
}

export function nextStep() {
  return {
    type: TYPES.NEXT_STEP,
  };
}

export function prevStep() {
  return {
    type: TYPES.PREV_STEP,
  };
}

export function setBookingType(type = '') {
  return {
    type: TYPES.SET_BOOKING_TYPE,
    payload: type,
  };
}

export function setAppointmentTimes(data) {
  return {
    type: TYPES.FETCH_APPOINTMENT_TIMES,
    payload: data,
  };
}

export function setBookingData(data) {
  return {
    type: TYPES.SET_BOOKING_DATA,
    payload: data,
  };
}

export function setTotal(total) {
  return {
    type: TYPES.SET_TOTAL,
    payload: +total,
  };
}

export function resetState() {
  return { type: TYPES.RESET_STATE };
}
