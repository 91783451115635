import React from 'react';
import { colors } from '../../assets/styles/colors';

const SocialFollow = ({ title, links, description }) => {
  return (
    <section className='mw-100 bg-pink pv6 social-follow'>
      <div className='flex flex-column justify-center items-center w-75 social-follow-inner'>
        <div className='mw6 tc'>
          <h2 className='f3 fw6 ttu mb5'>{title}</h2>
          <p className='lh-copy mb5'>{description}</p>
          <div className='flex items-center justify-center'>
            {links.map((link) => (
              <a
                key={link.id}
                href={link.link}
                className='ttu link mr3'
                style={{ color: colors.GREEN }}
              >
                {link.platform}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialFollow;
