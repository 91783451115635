import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='absolute bottom-0 w-100 pink mt3 flex flex-row justify-between pa4 f3-ns f5'>
      <div className='flex justify-center items-center'>
        <a
          href='https://www.instagram.com/forevereternalmoments'
          className='f5-ns f6 fw7 ttu link pink hover-green mr3'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-instagram'
          >
            <rect x='2' y='2' width='20' height='20' rx='5' ry='5'></rect>
            <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z'></path>
            <line x1='17.5' y1='6.5' x2='17.51' y2='6.5'></line>
          </svg>
        </a>
      </div>
      <div className='flex flex-column justify-center items-center '>
        Forever Eternal Moments © {year}
      </div>
    </footer>
  );
};

export default Footer;
