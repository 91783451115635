import React from 'react';

const PackageContainer = ({ name, description, price }) => {
  return (
    <>
      <div className='w-30-l w-100 pa5 ba b--lavender mh3 mv2 mv0-l'>
        <h2 className='f2 tracked saol fw7 mb3'>
          ${price} <span className='f4'>+ optional add-ons</span>
        </h2>
        <h2 className='f3 mb3 tracked'>{name}</h2>
        <p
          className='f4 tracked'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </>
  );
};

export default PackageContainer;
