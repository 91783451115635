import React from 'react';
import { BOOKING_TYPES } from '../../../_constants';

const InitialSelection = ({ totalPortraits, totalRentals, handleClick }) => {
  return (
    <section className=''>
      <header className='w-100 bb b--black-10 pa3 ttu tracked'>
        Select a Booking Type
      </header>
      <article
        className='dt w-100 bb b--black-05 pa3 hover-bg-near-white pointer'
        onClick={() => handleClick(BOOKING_TYPES.PORTRAIT)}
      >
        <div className='dtc v-mid'>
          <h2 className='f6 f5-ns fw6 lh-title black mv0 tracked'>
            {BOOKING_TYPES.PORTRAIT} &nbsp;
            <span className='f5-ns f6 fw4 lh-copy black mv0 tracked'>
              ({totalPortraits})
            </span>
          </h2>
        </div>
        <div className='dtc v-mid'>
          <div className='w-100 tr'>
            <button className='f6 button-reset bg-transparent bn b--black-10 dim pointer pv1 black-60'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevron-right'
              >
                <polyline points='9 18 15 12 9 6' />
              </svg>
            </button>
          </div>
        </div>
      </article>
      <article
        className='dt w-100 bb b--black-05 pa3 hover-bg-near-white pointer'
        onClick={() => handleClick(BOOKING_TYPES.RENTAL)}
      >
        <div className='dtc v-mid'>
          <h2 className='f6 f5-ns fw6 lh-title black mv0 tracked'>
            {BOOKING_TYPES.RENTAL}&nbsp;
            <span className='f5-ns f6 fw4 lh-copy black mv0 tracked'>
              ({totalRentals})
            </span>
          </h2>
        </div>
        <div className='dtc v-mid'>
          <div className='w-100 tr'>
            <button className='f6 button-reset bg-transparent bn b--black-10 dim pointer pv1 black-60'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevron-right'
              >
                <polyline points='9 18 15 12 9 6' />
              </svg>
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default InitialSelection;
