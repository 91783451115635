import React, { useState, useContext, memo } from 'react';
import { motion } from 'framer-motion';
import { NavLink, Link } from 'react-router-dom';

import { store } from '../../context/store';
import { toggleBookingOpen } from '../../context/actions';
import MobileNav from './MobileNav';
import { getLogoFromState } from '../../utils';
import Image from '../Loader/Image';

const Nav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { state, dispatch } = useContext(store);

  const toggleNav = () => {
    setMobileNavOpen((prevState) => !prevState);
  };
  return (
    <>
      {mobileNavOpen && (
        <MobileNav
          navLinks={state?.homePage?.navigation?.navLink}
          toggleNav={toggleNav}
        />
      )}
      <motion.header
        initial={{ opacity: 0, y: -180 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: 'easeInOut',
          duration: 1,
          delay: 0.6,
        }}
        className='header ttc  tracked'
      >
        <div className='header-inner'>
          <div className='logo'>
            <Link className='link pink dim' to='/'>
              {state?.homePage?.navigation?.logo ? (
                <Image
                  src={getLogoFromState(state)}
                  fallback={getLogoFromState(state)}
                  type={state?.homePage?.navigation?.logo?.mime}
                  alt={state?.homePage?.navigation?.logo?.alternativeText}
                />
              ) : (
                'F E M'
              )}
            </Link>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <nav className='nav'>
              {state?.homePage?.navigation?.navLink.map((link) => (
                <li key={link.id}>
                  <NavLink
                    to={link.path}
                    className='pink hover-lavender fw5'
                    exact
                    activeClassName='text-lavender saol fw7'
                  >
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </nav>
            <div className='book-now pointer hover-lavender ttu fw5'>
              <div
                onClick={() => dispatch(toggleBookingOpen(!state.bookingOpen))}
              >
                book now
              </div>
            </div>
          </div>

          <div onClick={() => toggleNav()} className='hamburger-menu'>
            <span></span>
            <span></span>
          </div>
        </div>
      </motion.header>
    </>
  );
};

export default memo(Nav);

// import React, { useRef } from 'react';
// import { motion } from 'framer-motion';

// import { useClickedOutside } from '../../hooks/useClickedOutside';

// import logo from '../../assets/images/logo.png';
// const Nav = ({ shouldShow }) => {
//   const location = useLocation();

//   const navContainer = useRef();
//   useClickedOutside(navContainer, () => {
//     document.getElementById('burger').checked = false;
//   });

//   const isHome = location.pathname === '/';

//   return (
//     <motion.nav
//       ref={navContainer}
//       animate={{ opacity: isHome && shouldShow ? 1 : !isHome ? 1 : 0 }}
//       initial={{ opacity: 0 }}
//       transition={{ opacity: { duration: 0.2 } }}
//       className='w-100 fixed top-0 bg-pink sans-serif pa3 flex justify-between'
//     >
//       <input
//         type='checkbox'
//         className='absolute top-2 right-1 dn'
//         id='burger'
//       />
//       <label
//         htmlFor='burger'
//         className='dn-l pointer absolute top-1 right-1 f5-ns f6 fw7 ttu link hover-lavender black mt3'
//       >
//         Menu
//       </label>

//       <ul className='overflow-hidden menu flex-l justify-between-l w-100 list tc pl0 mv0 f3 fw3 f5-l'>
//         <div className='w-25'>
//           <li className='pt2'>
//             <NavLink
//               to='/'
//               activeClassName='text-green'
//               className='flex justify-start black link'
//             >
//               <img
//                 src={logo}
//                 alt='dwyl heart logo'
//                 className='w-100-m w-50-l'
//               />
//             </NavLink>
//           </li>
//         </div>
//         <div className='flex-l justify-center-l items-center-l'>
//           <li className='ph4 pt4-m pb2-m tl di-l'>
//             <NavLink
//               to='/'
//               exact
//               activeClassName='text-green'
//               className='f5-ns f6 fw7 ttu link hover-lavender black'
//             >
//               Home
//             </NavLink>
//           </li>
//           <li className='ph4 di-l pv2 tl pv0-l'>
//             <NavLink
//               to='/about'
//               className='f5-ns f6 fw7 ttu link hover-lavender black'
//               exact
//               activeClassName='text-green'
//             >
//               about
//             </NavLink>
//           </li>
//           <li className='ph4 di-l pv2 tl pv0-l'>
//             <NavLink
//               to='/portraits'
//               className='f5-ns f6 fw7 ttu link hover-lavender black'
//               exact
//               activeClassName='text-green'
//             >
//               portraits
//             </NavLink>
//           </li>
//           <li className='ph4 di-l pv2 tl pv0-l'>
//             <NavLink
//               to='/rentals'
//               className='f5-ns f6 fw7 ttu link hover-lavender black'
//               exact
//               activeClassName='text-green'
//             >
//               rentals
//             </NavLink>
//           </li>
//           <li className='ph4 di-l pt2 pb3 tl pv0-l'>
//             <NavLink
//               to='/contact'
//               id='contact-link'
//               className='f5-ns f6 fw7 ttu link hover-lavender black'
//               exact
//               activeClassName='text-green'
//             >
//               Contact
//             </NavLink>
//           </li>
//         </div>
//       </ul>
//     </motion.nav>
//   );
// };

// export default Nav;
