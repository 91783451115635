export const FETCH_APPOINTMENT_TYPES = 'FETCH_APPOINTMENT_TYPES';
export const FETCH_APPOINTMENT_ADDONS = 'FETCH_APPOINTMENT_ADDONS';
export const FETCH_APPOINTMENT_DATES = 'FETCH_APPOINTMENT_DATES';
export const FETCH_APPOINTMENT_TIMES = 'FETCH_APPOINTMENT_TIMES';

export const SET_APPOINTMENT_TYPE_ID = 'SET_APPOINTMENT_TYPE_ID';

export const SET_HOMEPAGE = 'SET_HOMEPAGE';
export const SET_PORTRAITS_PAGE = 'SET_PORTRAITS_PAGE';
export const SET_RENTALS_PAGE = 'SET_RENTALS_PAGE';

export const TOGGLE_BOOKING_OPEN = 'TOGGLE_BOOKING_OPEN';

export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';

export const SET_BOOKING_TYPE = 'SET_BOOKING_TYPE';
export const SET_BOOKING_DATA = 'SET_BOOKING_DATA';
export const SET_TOTAL = 'SET_TOTAL';
export const RESET_STATE = 'RESET_STATE';
