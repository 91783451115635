import React, { useEffect, useCallback, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { store } from './context/store';
import { Home, Rentals, Portraits } from './Pages';
import { getHomePage } from './services';
import { setHomePage } from './context/actions';
import { Booking } from './components';

function App() {
  const { state, dispatch } = useContext(store);

  const loadHomePage = useCallback(async () => {
    try {
      const homePage = await getHomePage();
      homePage && dispatch(setHomePage(homePage));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    loadHomePage();
  }, [loadHomePage]);

  return (
    <Router>
      <ScrollToTop />
      <Booking open={state.bookingOpen} />
      <Switch>
        <Route path='/' exact render={() => <Home />} />
        <Route path='/portraits' render={({ history }) => <Portraits />} />
        <Route path='/rentals' render={({ history }) => <Rentals />} />
        {/* <Route path='/contact' component={Contact} /> */}
        <Redirect to='/' />
      </Switch>
    </Router>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
