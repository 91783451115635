import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  text-align: left;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: white;
  transition: transform 0.3s ease-in-out;
  overflow-y: scroll;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;

export const Inner = styled.div`
  width: 100vw;
  height: auto;
  @media (min-width: 768px) {
    width: 50vmin;
  }

  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const Header = styled.header`
  height: 15vmin;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.PINK};

  @media screen and (max-width: 414px) {
    height: 30vmin;
  }
`;
