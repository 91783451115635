import React from 'react';

const Selection = ({ item, isAddon, handleClick, removeAddon, isSelected }) => {
  return (
    <article
      className={`${
        isSelected && 'bg-near-white'
      } dt w-100 bb b--black-05 pa3 hover-bg-near-white pointer`}
      onClick={
        isAddon && isSelected
          ? () => removeAddon(item.id)
          : isAddon
          ? () => handleClick(item)
          : () => handleClick(item.id, item)
      }
    >
      <div className='dtc v-mid'>
        <h2
          className={`f6 f5-ns fw6 lh-title black mv0 flex ${
            isAddon && 'flex-column'
          } justify-between items-baseline tracked`}
        >
          {item.name}
          {item?.price && (
            <span className='f5-ns f6 fw4 lh-copy black mv0 tracked mr2'>
              ${item.price}
            </span>
          )}
        </h2>
        {item.description && (
          <p
            className='f6 lh-copy tracked'
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>
      <div className='dtc v-mid'>
        <div className='w-100 tr'>
          {isAddon ? (
            <input type='checkbox' />
          ) : (
            <button className='f6 button-reset bg-transparent bn b--black-10 dim pointer pv1 black-60'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevron-right'
              >
                <polyline points='9 18 15 12 9 6' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </article>
  );
};

export default Selection;
