import React, { createContext, useReducer } from 'react';
import reducer from './reducer';

export const initialState = {
  appointmentAddons: null,
  appointmentTypeId: null,
  appointmentDates: null,
  appointmentTimes: null,
  appointmentTypes: {
    portraits: [],
    rentals: [],
  },
  step: 0,
  bookingState: {
    0: {
      text: 'select booking type',
    },
    1: {
      text: (type) => `select a ${type} package`,
      bookingType: '',
    },
    2: {
      text: (type) => `select a addons for your ${type} package`,
      appointmentTypeId: '',
      selectedAppointment: {},
    },
    3: {
      text: 'Review Your Booking',
      bookingData: {
        appointment: {
          appointmentTypeID: null,
          datetime: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          customerId: '',
          addonIDS: [],
        },
        lineItems: {
          appointment: {},
          addons: [],
        },
      },
    },
    4: {
      text: 'Complete Your Booking',
      total: null,
    },
    5: {
      text: 'Complete Your Booking',
    },
  },
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
