import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { store } from '../../context/store';
import { colors } from '../../assets/styles/colors';
import { toggleBookingOpen } from '../../context/actions';
import { getLogoFromState } from '../../utils';
import Image from '../Loader/Image';

const MobileNav = ({ toggleNav, navLinks }) => {
  const { state, dispatch } = useContext(store);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: 'easeInOut',
        duration: 1,
        delay: 0,
      }}
      className='mobileNav fixed bg-pink black vh-100 w-100 pt7-l pt6 z-3'
    >
      <motion.header
        initial={{ opacity: 0, y: -180 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -180 }}
        transition={{
          ease: 'easeInOut',
          duration: 1,
          delay: 0.6,
        }}
        className='header'
        style={{ top: '0', background: colors.PINK, color: colors.BLACK }}
      >
        <div className='header-inner'>
          <div className='logo'>
            <Link className='link black dim' to='/'>
              {state?.homePage?.navigation?.logo ? (
                <Image
                  src={getLogoFromState(state)}
                  fallback={getLogoFromState(state)}
                  type={state?.homePage?.navigation?.logo?.mime}
                  alt={state?.homePage?.navigation?.logo?.alternativeText}
                />
              ) : (
                'F E M'
              )}
            </Link>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='book-now'>
              <div
                onClick={() => dispatch(toggleBookingOpen(!state.bookingOpen))}
                className='black hover-lavender pointer ttu fw5'
                style={{
                  borderBottom: `2px solid ${colors.BLACK}`,
                }}
              >
                book now
              </div>
            </div>
          </div>

          <div onClick={() => toggleNav()} className='hamburger-menu'>
            <span
              className='black hover-lavender'
              style={{ background: 'black' }}
            ></span>
            <span
              className='black hover-lavender'
              style={{ background: 'black' }}
            ></span>
          </div>
        </div>
      </motion.header>
      <nav className='ttc list flex flex-column justify-between items-center h-50'>
        {navLinks.map((link) => (
          <li key={link.id}>
            <NavLink
              to={link.path}
              className='black f-subheadline-l fw5  f1 link hover-lavender'
              exact
              activeClassName='text-lavender saol fw7'
            >
              {link.label}
            </NavLink>
          </li>
        ))}
      </nav>
    </motion.div>
  );
};

export default MobileNav;
