import React, { useContext } from 'react';
import { nextStep, prevStep, setTotal } from '../../../context/actions';
import { store } from '../../../context/store';

const Review = ({ text, date, lineItems }) => {
  const { dispatch } = useContext(store);
  const { appointment, addons } = lineItems;
  const getTotal = () => {
    let total = 0;

    addons.forEach((addon) => (total = total + +addon.price));
    total = total + +appointment.price;
    return total;
  };

  const continueToPayment = (total) => {
    dispatch(setTotal(total));
    dispatch(nextStep());
  };

  return (
    <section className='tracked'>
      <header className='w-100 bb b--black-10 pa3 ttu tracked'>{text}</header>
      <article
        className='dt w-100 bb b--black-05 pa3 hover-bg-near-white pointer flex justify-between items-center'
        onClick={() => dispatch(prevStep())}
      >
        <div className='dtc v-mid'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-chevron-left'
          >
            <polyline points='15 18 9 12 15 6' />
          </svg>
        </div>

        <div className='dtc v-mid'>
          <h2 className='f6 f5-ns fw6 lh-title black mv0 tracked'>
            Change booking info
          </h2>
        </div>
      </article>
      <article className=' w-100 bb b--black-10 pa3'>
        <div className='flex justify-between items-center'>
          <p className='f5 tracked'>Location</p>
          <p className='f5 tracked'>FEM</p>
        </div>
      </article>
      <article className=' w-100 bb b--black-10 pa3'>
        <div className='flex justify-between items-center '>
          <p className='f5 tracked'>Date & Time</p>
          <p
            onClick={() => dispatch(prevStep())}
            className='f5 tracked hover-blue pointer'
          >
            {date}
          </p>
        </div>
      </article>
      <article className=' w-100 bb b--black-10 pa3'>
        <div className='flex flex-column'>
          <div className='flex justify-between items-center gray'>
            <p className='f5 tracked'>Items</p>
            <p className='f5 tracked'>Cost</p>
          </div>

          <div className='bb b--black-10 pv3'>
            <div className='flex justify-between items-center '>
              <p className='f6'>{appointment.name}</p>
              <p className='f6'>${+appointment.price}</p>
            </div>
          </div>
          {addons?.map((addon) => (
            <div className='bb b--black-10 pv3 '>
              <div className='flex justify-between items-center '>
                <p className='f6'>{addon?.name}</p>
                <p className='f6'>${+addon?.price}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='pv3'>
          <div className='flex justify-between items-center green '>
            <p className='f5'>Total</p>
            <p className='f5'>${getTotal()}</p>
          </div>
        </div>
      </article>

      <article
        className='dt w-100 bb b--black-05 pa3 hover-bg-near-white bg-lavender pointer absolute bottom-0'
        onClick={() => continueToPayment(getTotal())}
      >
        <div className='dtc v-mid'>
          <h2 className='f6 f5-ns fw6 lh-title black mv0 tracked '>
            Continue to Payment
          </h2>
        </div>
        <div className='dtc v-mid'>
          <div className='w-100 tr'>
            <button className='f6 button-reset bg-transparent bn b--black-10 dim pointer pv1 black-60'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevron-right'
              >
                <polyline points='9 18 15 12 9 6' />
              </svg>
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Review;
