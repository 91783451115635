import React, { useContext, useEffect, useCallback, useState } from 'react';

import moment from 'moment';
import {
  getAppointmentTypes,
  getAppointmentAddons,
  getAppointmentDates,
  getAppointmentTimes,
} from '../../services';
import {
  setAppointmentTypes,
  setAppointmentAddons,
  setAppointmentDates,
  setAppointmentTimes,
  toggleBookingOpen,
  setBookingType,
  nextStep,
  prevStep,
  setAppointmentTypeId,
} from '../../context/actions';
import Image from '../Loader/Image';
import { getLogoFromState } from '../../utils';

import { store } from '../../context/store';
import * as Styled from './styles';
import InitialSelection from './InitialSelection';
import SelectionContainer from './SelectionContainer';
import { BOOKING_TYPES } from '../../_constants';
import Review from './Review';
import CustomerCreate from './CustomerCreate';
import Pay from './Pay';
import LoaderContainer from './Loader';

const Booking = () => {
  const fullDate = moment();
  const { state, dispatch } = useContext(store);
  const { bookingState } = state;
  const [loading, setLoading] = useState(false);

  const loadTypes = useCallback(async () => {
    try {
      const apptTypes = await getAppointmentTypes();
      apptTypes && dispatch(setAppointmentTypes(apptTypes));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const loadAddons = useCallback(async () => {
    try {
      const apptAddons = await getAppointmentAddons();
      apptAddons && dispatch(setAppointmentAddons(apptAddons));
      return 'done';
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const loadDates = useCallback(
    async (appointmentTypeId, addonIds, month) => {
      try {
        const apptDates = await getAppointmentDates(
          appointmentTypeId,
          addonIds,
          month
        );
        apptDates && dispatch(setAppointmentDates(apptDates));
        return 'done';
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const loadAppointment = useCallback(
    async (appointmentTypeId, appointment, addonIDs, month) => {
      setLoading(true);
      const addons = await loadAddons();
      const dates = await loadDates(appointmentTypeId, addonIDs, month);
      dispatch(setAppointmentTypeId(appointmentTypeId, appointment));

      if (addons === 'done' && dates === 'done') {
        setLoading(false);
      }
    },
    [dispatch, loadAddons, loadDates]
  );

  const loadTimes = useCallback(
    async (appointmentTypeId, addonIds, date) => {
      try {
        const apptTimes = await getAppointmentTimes(
          appointmentTypeId,
          addonIds,
          date
        );
        apptTimes && dispatch(setAppointmentTimes(apptTimes));
      } catch (error) {
        console.error(error);
      } finally {
        return 'done';
      }
    },
    [dispatch]
  );

  const handleBookingTypeSelection = (bookingType) => {
    dispatch(setBookingType(bookingType));
    dispatch(nextStep());
  };

  const handleAppointmentTypeSelection = (id, appointment = {}) => {
    loadAppointment(
      id,
      appointment,
      appointment.addonIDs,
      `${fullDate.format('YYYY')}-${fullDate.format('MM')}`
    );
    dispatch(nextStep());
  };

  useEffect(() => {
    loadTypes();
  }, [loadTypes]);

  return (
    <Styled.Container open={state.bookingOpen}>
      <Styled.Inner>
        <Styled.Header>
          {state.step > 0 && state.step !== 5 && (
            <div
              onClick={() => dispatch(prevStep())}
              className='pointer absolute black top-0 left-0 pa3-ns pa2'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevron-left'
              >
                <polyline points='15 18 9 12 15 6' />
              </svg>
            </div>
          )}

          <Image
            src={getLogoFromState(state)}
            fallback={getLogoFromState(state)}
            type={state?.homePage?.navigation?.logo?.mime}
            alt={state?.homePage?.navigation?.logo?.alternativeText}
          />
          <div
            className='pointer absolute black top-0 right-0 pa3-ns pa2'
            onClick={() => dispatch(toggleBookingOpen(!state.bookingOpen))}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-x'
            >
              <line x1='18' y1='6' x2='6' y2='18' />
              <line x1='6' y1='6' x2='18' y2='18' />
            </svg>
          </div>
        </Styled.Header>
        {state.step === 0 && (
          <InitialSelection
            totalPortraits={state?.appointmentTypes?.portraits?.length}
            totalRentals={state?.appointmentTypes?.rentals?.length}
            handleClick={handleBookingTypeSelection}
          />
        )}
        {state.step === 1 && bookingState[state.step].bookingType && (
          <SelectionContainer
            {...bookingState[state.step]}
            items={
              bookingState[state.step].bookingType === BOOKING_TYPES.PORTRAIT
                ? state.appointmentTypes.portraits
                : state.appointmentTypes.rentals
            }
            handleClick={handleAppointmentTypeSelection}
          />
        )}
        {state.step === 2 && !loading ? (
          <SelectionContainer
            text={bookingState[state.step].text}
            apptName={bookingState[state.step].selectedAppointment.name}
            appt={bookingState[state.step].selectedAppointment}
            dates={state?.appointmentDates}
            addons={state?.appointmentAddons}
            loadTimes={loadTimes}
            times={state?.appointmentTimes}
          />
        ) : (
          loading && <LoaderContainer />
        )}
        {state.step === 3 && (
          <Review
            text={bookingState[state.step].text}
            date={moment(
              bookingState[state.step].bookingData.appointment.datetime
            ).format('LLL')}
            lineItems={bookingState[state.step].bookingData.lineItems}
          />
        )}
        {state.step === 4 && (
          <CustomerCreate text={bookingState[state.step].text} />
        )}
        {state.step === 5 && (
          <Pay
            text={bookingState[state.step].text}
            total={bookingState[4].total}
            appt={moment(
              bookingState[3].bookingData.appointment.datetime
            ).format('LLL')}
          />
        )}
      </Styled.Inner>
    </Styled.Container>
  );
};

export default Booking;
