import React from 'react';
import Footer from './Footer';
import Nav from './Nav';

const Layout = ({ children }) => {
  return (
    <>
      <div
        className='relative db min-vh-100'
        style={{ paddingBottom: '10rem' }}
      >
        <Nav />
        {children}

        <Footer />
      </div>
    </>
  );
};

export default Layout;
