import axios from 'axios';
import { getApi } from '../utils';

const { API_URL } = getApi(window.location);

export const getAppointmentTypes = () => {
  return axios
    .get(`${API_URL}/acuity/appointments`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getAppointmentAddons = () => {
  return axios
    .get(`${API_URL}/acuity/appointment-addons`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getAppointmentDates = (
  appointmentTypeId,
  addonIDs = [],
  month = ''
) => {
  return axios
    .post(`${API_URL}/acuity/appointment-dates`, {
      appointmentTypeId,
      addonIDs,
      month,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getAppointmentTimes = (
  appointmentTypeId,
  addonIDs = [],
  date = ''
) => {
  return axios
    .post(`${API_URL}/acuity/appointment-times`, {
      appointmentTypeId,
      addonIDs,
      date,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const postMessage = (message) => {
  return axios.post(`${API_URL}/messages`, message).then((res) => {
    return res;
  });
};

export const getHomePage = () => {
  return axios
    .get(`${API_URL}/home-page`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getPortraitsPage = () => {
  return axios
    .get(`${API_URL}/portrait-page`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
export const getRentalsPage = () => {
  return axios
    .get(`${API_URL}/rental-page`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const createCustomer = ({ firstName, lastName, email, phone }) => {
  const body = {
    emailAddress: email,
    phoneNumber: phone,
    familyName: lastName,
    givenName: firstName,
  };
  return axios.post(`${API_URL}/square/create-customer`, body).then((res) => {
    return res.data;
  });
};

export const createPayment = (body) => {
  return axios.post(`${API_URL}/square/create-payment`, body).then((res) => {
    return res.data;
  });
};

export const createAppointment = (body) => {
  return axios
    .post(`${API_URL}/acuity/create-appointment`, body)
    .then((res) => {
      return res.data;
    });
};
