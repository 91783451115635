import React, { useContext, useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';

import { Layout, Carousel, ClientList, LargeTextBlock } from '../../components';
import { store } from '../../context/store';

import { setRentalsPage } from '../../context/actions';
import { getRentalsPage } from '../../services';

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};
const Rentals = (props) => {
  const { state, dispatch } = useContext(store);
  const [playMarquee, setPlayMarquee] = useState(false);

  const loadRentalsPage = useCallback(async () => {
    const rentalsPage = await getRentalsPage();
    rentalsPage && dispatch(setRentalsPage(rentalsPage));
  }, [dispatch]);

  useEffect(() => {
    loadRentalsPage();
    setTimeout(() => {
      setPlayMarquee(true);
    }, 1000);
  }, [loadRentalsPage]);

  return (
    <Layout>
      <div className='page-container w-100 flex flex-column justify-center pink items-center overflow-hidden'>
        {playMarquee && (
          <motion.div className='portraits banner' variants={banner}>
            <BannerRowCenter
              title={[{ 1: 'Rentals' }, { 2: 'at' }, { 3: 'FEM' }]}
              playMarquee={playMarquee}
            />
          </motion.div>
        )}
        <div className='w-100 carousel mv6 db ph3'>
          <Carousel items={state?.rentalsPage?.carousel?.slides} />
        </div>
        <div className='w-100 bg-lavender pv6'>
          <h2 className='f1 tc mb4'>Our Clients</h2>
          <ClientList clients={state?.rentalsPage?.clientList} />
        </div>
        <div className='w-100 bg-white pv6'>
          <h2 className='f1 tc'>Our Rental Space</h2>
          <LargeTextBlock
            text={state?.rentalsPage?.description}
            padding='pt5-l pa5-m pa4'
          />
        </div>
      </div>
    </Layout>
  );
};

export default Rentals;

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div
      className={`portraits banner-row marquee  ${playMarquee && 'animate'}`}
    >
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.5 }}
        className='marquee__inner'
        style={{ height: '100%' }}
      >
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
      </motion.div>
    </div>
  );
};

const AnimatedLetters = ({ title, disabled, withSpaces }) => (
  <motion.span
    className='row-title portraits'
    variants={disabled ? null : banner}
    initial='initial'
    animate='animate'
  >
    {withSpaces
      ? title.map((words) =>
          Object.values(words).map((word) => (
            <motion.span
              className={`${word === 'at' ? 'saol' : ''} portraits row-letter`}
              variants={disabled ? null : letterAni}
            >
              {word}&nbsp;&nbsp;
            </motion.span>
          ))
        )
      : [...title].map((letter) => (
          <motion.span
            className='row-letter'
            variants={disabled ? null : letterAni}
          >
            {letter}
          </motion.span>
        ))}
  </motion.span>
);
