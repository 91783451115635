import * as TYPES from './types';
import { initialState } from './store';
const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.RESET_STATE: {
      return {
        ...state,
        step: 0,
        bookingState: {
          ...initialState.bookingState,
        },
      };
    }
    case TYPES.SET_TOTAL: {
      return {
        ...state,
        bookingState: {
          ...state.bookingState,
          4: {
            ...state.bookingState[4],
            total: action.payload,
          },
        },
      };
    }
    case TYPES.SET_BOOKING_DATA: {
      return {
        ...state,
        bookingState: {
          ...state.bookingState,
          3: {
            ...state.bookingState[3],
            bookingData: {
              appointment: {
                ...state.bookingState[3].bookingData.appointment,
                ...action.payload.appointment,
              },
              lineItems: {
                ...state.bookingState[3].bookingData.lineItems,
                ...action.payload.lineItems,
              },
            },
          },
        },
      };
    }
    case TYPES.NEXT_STEP: {
      return {
        ...state,
        step: state.step + 1,
      };
    }
    case TYPES.PREV_STEP: {
      return {
        ...state,
        step: state.step - 1,
      };
    }
    case TYPES.FETCH_APPOINTMENT_TYPES: {
      return {
        ...state,
        appointmentTypes: action.payload,
      };
    }
    case TYPES.FETCH_APPOINTMENT_TIMES: {
      return {
        ...state,
        appointmentTimes: action.payload,
      };
    }
    case TYPES.SET_BOOKING_TYPE: {
      return {
        ...state,
        bookingState: {
          ...state.bookingState,
          1: {
            ...state.bookingState[1],
            bookingType: action.payload,
          },
        },
      };
    }
    case TYPES.SET_APPOINTMENT_TYPE_ID: {
      return {
        ...state,
        appointmentTypeId: action.payload.id,

        bookingState: {
          ...state.bookingState,
          2: {
            ...state.bookingState[2],
            appointmentTypeId: action.payload.id,
            selectedAppointment: action.payload.selectedAppointment,
          },
        },
      };
    }
    case TYPES.FETCH_APPOINTMENT_ADDONS: {
      return {
        ...state,
        appointmentAddons: action.payload,
      };
    }
    case TYPES.FETCH_APPOINTMENT_DATES: {
      return {
        ...state,
        appointmentDates: action.payload,
      };
    }
    case TYPES.SET_HOMEPAGE: {
      return {
        ...state,
        homePage: action.payload,
      };
    }
    case TYPES.SET_PORTRAITS_PAGE: {
      return {
        ...state,
        portraitsPage: action.payload,
      };
    }
    case TYPES.SET_RENTALS_PAGE: {
      return {
        ...state,
        rentalsPage: action.payload,
      };
    }
    case TYPES.TOGGLE_BOOKING_OPEN: {
      return {
        ...state,
        bookingOpen: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
