import { memo, useContext } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { store } from '../../context/store';
import {
  Banner,
  LargeTextBlock,
  Layout,
  Loader,
  SocialFollow,
  // TextWithImage,
} from '../../components';
import useLoading from '../../hooks/useLoading';
const Home = () => {
  const { loading, setLoading } = useLoading();
  const { state } = useContext(store);
  return (
    <AnimateSharedLayout type='crossfade'>
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <>
            <Layout>
              <Banner message={state?.homePage?.tagline} />
              {!loading && (
                <div className='transition-image final'>
                  <motion.img
                    transition={{
                      ease: [0.6, 0.01, -0.05, 0.9],
                      duration: 1.6,
                    }}
                    src={state?.homePage?.mainImage.url}
                    layoutId='main-image-1'
                  />
                </div>
              )}
              <LargeTextBlock
                text={state?.homePage?.studioDescription.text}
                padding='pv6-l pa5-m pa4'
              />
              {/* <TextWithImage /> */}
              <SocialFollow
                title={state?.homePage?.socialFollow?.title}
                links={state?.homePage?.socialFollow?.socialLink}
                description={state?.homePage?.socialFollow?.description}
              />
            </Layout>
          </>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
};

export default memo(Home);
// const Home = () => {
//   return (
//     <>
//       <div className=' top-container flex flex-column justify-center items-center'>
//         <img className='mb5' src={logo} alt='Forever Eternal Moments logo' />
//         <div className='flex justify-center items-center'>
//           <Link
//             style={{ whiteSpace: 'nowrap' }}
//             className='f4-ns f5 fw7 ttu link br-pill ba bw1 ph3 dib pv2 black hover-yellow mr3'
//             to='/portraits'
//           >
//             Portraits
//           </Link>
//           <Link
//             style={{ whiteSpace: 'nowrap' }}
//             className='f4-ns f5 fw7 ttu link br-pill ba bw1 ph3 dib pv2 black hover-yellow mr3'
//             to='/rentals'
//           >
//             Rentals
//           </Link>
//         </div>
//       </div>
//       <div className='vh-100'>
//         <div className='flex justify-center items-center'>Gideon Full</div>
//       </div>
//     </>
//   );
// };

// export default Home;
