import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { nextStep, setBookingData } from '../../../context/actions';
import { store } from '../../../context/store';
import { createCustomer } from '../../../services';
import InputGroup from '../../InputGroup';

const CustomerCreate = ({ text }) => {
  const {
    register,
    handleSubmit,

    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { dispatch } = useContext(store);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await createCustomer(data);

      if (res?.customer) {
        dispatch(setBookingData({ appointment: res.customer }));
      }
    } catch (error) {
      console.log(error);
      return;
    } finally {
      setLoading(false);
      setSuccess(true);
      dispatch(nextStep());
    }
  };

  return (
    <section className='tracked'>
      <header className='w-100 bb b--black-10 pa3 ttu tracked'>{text}</header>
      <h3 className='pa3'>Contact Info</h3>
      <form
        style={{ margin: '0 auto' }}
        onSubmit={handleSubmit(onSubmit)}
        className='pa3 black-80 mw7-l mw9'
      >
        {/* Name */}
        <div className='w-100 flex flex-column justify-between mb3'>
          <InputGroup>
            <div>
              <input
                className='bl-0 br-0 bt-0 b--black black bg-transparent  w-100'
                placeholder='E.g. Pharrell'
                {...register('firstName', { required: true })}
              />
              <label htmlFor='firstName' className='f6 b db mv2'>
                <span className='f6 normal red'>*</span>First Name
              </label>
              {errors.firstName && (
                <span className='red f6'>First name is required</span>
              )}
            </div>
          </InputGroup>
          <InputGroup>
            <div>
              <input
                className='bl-0 br-0 bt-0 b--black black bg-transparent w-100'
                placeholder='E.g. Williams'
                {...register('lastName', { required: true })}
              />
              <label htmlFor='lastName' className='f6 b db mv2'>
                <span className='f6 normal red'>*</span>Last Name
              </label>
              {errors.lastName && (
                <span className='red f6'>Last name is required</span>
              )}
            </div>
          </InputGroup>
        </div>
        {/* Email & Phone */}
        <div className='w-100 flex flex-column justify-between mb3'>
          <InputGroup>
            <div>
              <input
                type='email'
                className='bl-0 br-0 bt-0 b--black black bg-transparent  w-100'
                placeholder='Email'
                {...register('email', { required: true })}
              />
              <label htmlFor='email' className='f6 b db mv2'>
                <span className='f6 normal red'>*</span>Email
              </label>
              {errors.email && (
                <span className='red f6'>Email address is required</span>
              )}
            </div>
          </InputGroup>
          <InputGroup>
            <input
              className='bl-0 br-0 bt-0 b--black black bg-transparent  w-100'
              placeholder='Phone'
              {...register('phone', { required: true })}
            />
            <label htmlFor='phone' className='f6 b db mv2'>
              <span className='f6 f6 normal red'>*</span>Phone
            </label>

            {errors.phone && (
              <span className='red f6'>Email address is required</span>
            )}
          </InputGroup>
        </div>
        <div className='mb3 w-100 tc'>
          {loading && (
            <span className=' green mb2 f5'>Creating your account...</span>
          )}
          {success && <span className=' green mb2 f5'>Account created!</span>}
        </div>

        <input
          disabled={!isValid}
          type='submit'
          //   className='f5-ns f6 fw7 ttu link hover-yellow black mr6-ns bt-0 bb-0 bl-0 bt-0 br-0 pointer bg-transparent pa0'
          className='dt w-100 bb b--black-05 pa3 hover-bg-near-white bg-lavender bottom-0 pointer'
        />
      </form>
    </section>
  );
};

export default CustomerCreate;
