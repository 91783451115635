import React, { useContext, useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { Layout, Carousel, PackageContainer } from '../../components';
import { store } from '../../context/store';

import { setPortraitsPage } from '../../context/actions';
import { getPortraitsPage } from '../../services';

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const Portraits = (props) => {
  const { state, dispatch } = useContext(store);
  const [playMarquee, setPlayMarquee] = useState(false);

  const loadPortraitsPage = useCallback(async () => {
    const portraitsPage = await getPortraitsPage();
    portraitsPage && dispatch(setPortraitsPage(portraitsPage));
  }, [dispatch]);

  useEffect(() => {
    loadPortraitsPage();
    setTimeout(() => {
      setPlayMarquee(true);
    }, 1000);
  }, [loadPortraitsPage]);

  const getVideoComponentUrl = (field) => {
    switch (field?.__component) {
      case 'pages.video-link':
        return field?.videoUrl;
      case 'pages.video-file':
        return field?.video[0].url;
      default:
        break;
    }
  };
  return (
    <Layout>
      <div className='page-container w-100 flex flex-column justify-center pink items-center overflow-hidden'>
        {playMarquee && (
          <motion.div className='portraits banner' variants={banner}>
            <BannerRowCenter
              title={[{ 1: 'Portraits' }, { 2: 'at' }, { 3: 'FEM' }]}
              playMarquee={playMarquee}
            />
          </motion.div>
        )}
        <div className='w-100 carousel mv6 db ph3'>
          <Carousel items={state?.portraitsPage?.carousel?.slides} />
        </div>
        <div className='w-100 mv4 bg-lavender pa6 flex flex-column justify-center items-center'>
          <h2 className='f1 mb4'>
            {state?.portraitsPage?.Featurette[0].title}
          </h2>

          <ReactPlayer
            url={getVideoComponentUrl(state?.portraitsPage?.Featurette[0])}
            controls
          />
        </div>

        <div className='ph3'>
          <h2 className='f1 mb4 tc'>Portrait Packages</h2>
          <div className='w-100 flex flex-wrap justify-center'>
            {state?.appointmentTypes?.portraits?.map((pkg) => (
              <PackageContainer {...pkg} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Portraits;

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div
      className={`portraits banner-row marquee  ${playMarquee && 'animate'}`}
    >
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.5 }}
        className='marquee__inner'
        style={{ height: '100%' }}
      >
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
        <AnimatedLetters withSpaces title={title} disabled />
      </motion.div>
    </div>
  );
};

const AnimatedLetters = ({ title, disabled, withSpaces }) => (
  <motion.span
    className='row-title portraits'
    variants={disabled ? null : banner}
    initial='initial'
    animate='animate'
  >
    {withSpaces
      ? title.map((words) =>
          Object.values(words).map((word) => (
            <motion.span
              className={`${word === 'at' ? 'saol' : ''} portraits row-letter`}
              variants={disabled ? null : letterAni}
            >
              {word}&nbsp;&nbsp;
            </motion.span>
          ))
        )
      : [...title].map((letter) => (
          <motion.span
            className='row-letter'
            variants={disabled ? null : letterAni}
          >
            {letter}
          </motion.span>
        ))}
  </motion.span>
);
