import moment from 'moment';
export const getApi = (location) => {
  let API_URL;

  if (location && location.hostname.indexOf('localhost') > -1) {
    API_URL = 'http://localhost:1337';
  } else {
    API_URL = 'https://femapi-v1.herokuapp.com';
  }

  return { API_URL };
};

export const paginator = (items, currentPage, itemsPerPage) => {
  let page = currentPage || 1,
    perPage = itemsPerPage || 5,
    offset = (page - 1) * perPage,
    paginatedItems = items.slice(offset).slice(0, itemsPerPage),
    totalPages = Math.ceil(items.length / perPage);
  return {
    page,
    perPage,
    prePage: page - 1 ? page - 1 : null,
    nextPage: totalPages > page ? page + 1 : null,
    total: items.length,
    totalPages,
    data: paginatedItems,
  };
};

export const getLogoFromState = (state) => {
  return state?.homePage?.navigation?.logo.formats.thumbnail.url;
};

export const addRealMonth = (d) => {
  var fm = moment(d).add(1, 'M');
  var fmEnd = moment(fm).endOf('month');
  return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
    ? fm.add(1, 'd')
    : fm;
};
