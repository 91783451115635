import React from 'react';
import Loader from 'react-loader-spinner';
import { colors } from '../../../assets/styles/colors';

const LoaderContainer = () => {
  return (
    <div className='w-100 pa3 flex flex-column justify-center items-center vh-75'>
      <Loader type='Oval' color={colors.LAVENDER} height={80} width={80} />
    </div>
  );
};

export default LoaderContainer;
