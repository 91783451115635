import React from 'react';
import ReactMarkdown from 'react-markdown';

const LargeTextBlock = ({ text, padding }) => {
  return (
    <section
      className={`${padding} bg-white black flex justify-center items-center`}
    >
      <ReactMarkdown className='large-text-block f1-l f2-m mw9-l mw7-m mw6 lh-title'>
        {text}
      </ReactMarkdown>
    </section>
  );
};

export default LargeTextBlock;
