import React, { useContext, useEffect, useState, useRef } from 'react';

import { store } from '../../../context/store';
import { v4 as uuidv4 } from 'uuid';
import { createPayment, createAppointment } from '../../../services';
import { PaymentFormContainer } from './styles';
import { resetState, toggleBookingOpen } from '../../../context/actions';

const Pay = ({ text, total, appt }) => {
  const applicationId = process.env.REACT_APP_SQUARE_APP_ID;
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

  // const [scriptLoaded, setScriptLoaded] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [, setError] = useState();
  // const [success, setSuccess] = useState(false);
  const [loadedCard, setLoadedCard] = useState();
  const [receiptUrl, setReceiptUrl] = useState();
  const [bookingConfirmationUrl, setBookingConfirmationUrl] = useState();
  const { state, dispatch } = useContext(store);

  const statusContainer = useRef();
  const cardButton = useRef();

  useEffect(() => {
    console.log(applicationId, locationId);
    if (!window.Square) {
      setError('Square.js failed to load properly');
    }

    let payments;
    try {
      payments = window.Square.payments(applicationId, locationId);
    } catch (e) {
      console.error(e);
      //   statusContainer.current.className = 'missing-credentials';
      //   statusContainer.current.style.visibility = 'visible';
      //   return;
      return;
    }
    const initializeCard = async (payments) => {
      const card = await payments.card();
      await card.attach('#card-container');

      return card;
    };

    try {
      initializeCard(payments).then((res) => {
        setLoadedCard(res);
      });
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
  }, [applicationId, locationId]);

  const handlePaymentMethodSubmission = async (event, paymentMethod) => {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment request.
      cardButton.current.disabled = true;
      const token = await tokenize(paymentMethod);
      const paymentResults = await createPayment({
        idempotencyKey: uuidv4(),
        locationId,
        sourceId: token,
        amountMoney: { amount: total * 100, currency: 'USD' },
        customerId: state.bookingState[3].bookingData.appointment.customerId,
      });
      const {
        datetime,
        firstName,
        lastName,
        email,
        phone,
        appointmentTypeID,
        addonIDs,
      } = state.bookingState[3].bookingData.appointment;
      const bookingResults = await createAppointment({
        datetime,
        firstName,
        lastName,
        email,
        phone,
        appointmentTypeID,
        addonIDs,
      });

      if (paymentResults.payment.status === 'COMPLETED') {
        displayPaymentResults('SUCCESS');
        setReceiptUrl(paymentResults.payment.receipt);
      }
      if (bookingResults.confirmationPage) {
        setBookingConfirmationUrl(bookingResults.confirmationPage);
      }
      console.debug('Payment Success', paymentResults);
      console.debug('Booking Success', bookingResults);
    } catch (e) {
      cardButton.current.disabled = false;
      displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  };

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  return (
    <section className='tracked'>
      <header
        className={`${receiptUrl && 'tc'} w-100 bb b--black-10 pa3 ttu tracked`}
      >
        {receiptUrl ? 'Booking Completed' : text}
      </header>
      {!receiptUrl && <h3 className='pa3'>Payment Info</h3>}
      {receiptUrl && bookingConfirmationUrl && (
        <div className='mt3  flex flex-column justify-center items-center  '>
          <div className='mb3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='48'
              viewBox='0 0 24 24'
              fill='none'
              stroke='green'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-check-circle'
            >
              <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' />
              <polyline points='22 4 12 14.01 9 11.01' />
            </svg>
          </div>

          <div className='measure-narrow tc'>
            <h2 className='black-80 f4 tracked mb3'>
              Your payment was completed and your session is booked for {appt}
            </h2>
            <h3 className='f5 black tracked mb3'>
              Be sure to keep the receipt link or take a screenshot and bring it
              with you to the studio!{' '}
            </h3>
          </div>

          <a
            className='f5 link hover-blue tracked mb3'
            rel='noopener noreferrer'
            target='_blank'
            href={bookingConfirmationUrl}
          >
            View your confirmation info
          </a>
          <a
            className='f5 link hover-blue tracked'
            rel='noopener noreferrer'
            target='_blank'
            href={receiptUrl}
          >
            View your receipt
          </a>
          <article
            className='dt w-100 bb b--black-05 pa3 hover-bg-near-white bg-lavender pointer absolute bottom-0 tc'
            onClick={() => {
              dispatch(resetState());
              dispatch(toggleBookingOpen(false));
            }}
          >
            Close
          </article>
        </div>
      )}
      {!receiptUrl && (
        <PaymentFormContainer>
          <form id='payment-form'>
            <div id='card-container'></div>
            <button
              onClick={(e) => {
                handlePaymentMethodSubmission(e, loadedCard);
              }}
              id='card-button'
              type='button'
              ref={cardButton}
            >
              Pay ${total}
            </button>
          </form>
          <div ref={statusContainer} id='payment-status-container'></div>
        </PaymentFormContainer>
      )}
    </section>
  );
};

export default Pay;
